import { getAuth } from 'firebase/auth'
import { addDoc, collection, getDocs, getFirestore, query, serverTimestamp } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { app } from '../Fire'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import useRazorpay from "react-razorpay";
import Progress from '../Progress/Progress'
import './payment.css'

function Payment() {



    const Razorpay = useRazorpay();

    const handlePayment = async (params) => {


        const options = {
            key: "rzp_test_T9RKTc0Db6MH82", // Enter the Key ID generated from the Dashboard
            amount: "500", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Vedha Fashion",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                alert(response.razorpay_payment_id);
            },
            //   prefill: {
            //     name: "Piyush Garg",
            //     email: "youremail@example.com",
            //     contact: "9999999999",
            //   },


        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        rzp1.open();
    };






    let params = useParams()
    let navigate = useNavigate()

    let auth = getAuth(app)

    let db = getFirestore(app)
    let temp = []

    async function getcart() {
        try {
            const q = query(collection(db, "usercart"))
            const snapshot = await getDocs(q)
            snapshot.forEach((doc1) => {
                console.log(doc1.data())
                temp.push(doc1.data())
            })
            makeOrder()
        } catch (e) {
            alert(e)
        }
    }

    async function makeOrder() {
        try {

            await addDoc(collection(db, "orders"), {
                produts: temp,
                uid: auth.currentUser.uid,
                address: '' + params.allAddress,
                mobile: '' + params.num,
                time: serverTimestamp(),
                mode: 'Cash On Delivery',
                status: 'pending'
            })

            navigate('/success')
            alert("Done")
        } catch (e) {
            alert("" + e)


        }
    }

    return (
        <div>
            <Header />

            <div className='cartparent'>
                <div className='childpayment'>
                    <div className='Paymentdiv'>
                        <div className='paymenthead'>
                            <h1 className='payheading'>Payment Options</h1>
                        </div>
                        <div className='Deliveryaddress'>
                            <h2 className='DelAddress'>Delivery Address</h2>
                            <p className='CustomerName'>Muxammil</p>
                            <p className='MyAdd'>{params.allAddress}</p>
                            <div className='PhnDetails'>
                                <p className='Phonenbr'>Phone number</p>
                                <p className='MyNumber'>{params.num}</p>
                            </div>
                        </div>
                        <div className='paymentoptions'>
                            <h2 className='payoption'>PAYMENT OPTIONS</h2>
                            <div className='mypay'>
                                <div className='payment1'
                                    onClick={handlePayment}
                                >
                                    <input type="checkbox" id="pay-check" />
                                    <span className='payselect'></span>
                                    <p className='payname1'>Rpay</p>
                                </div>

                                <div className='payment1'>
                                    <input type="checkbox" id="pay-check" />
                                    <span className='payselect'></span>
                                    <p className='payname1'>Cash on delivery</p>
                                </div>
                            </div>
                        </div>
                        <div className='continuediv'>
                            <button className='continue'
                                onClick={() => {
                                    getcart()
                                }}
                            >CONTINUE</button>
                        </div>
                    </div>
                    <div className='PriceDiv'>
                        <h2 className='Price'>PRICE DETAILS</h2>
                        <div className='Myprice'>
                            <p className='Pname'>Price</p>
                            <p className='MyAmt'>₹19,900</p>
                        </div>
                        <div className='Myprice'>
                            <p className='Pname'>Discount</p>
                            <p className='MyAmt2'>-₹9,000</p>
                        </div>
                        <div className='Myprice'>
                            <p className='Pname'>Delivery Charges</p>
                            <p className='MyAmt2'>FREE</p>
                        </div>
                        <div className='Totalamountdiv'>
                            <h2 className='Total'>Total Amount </h2>
                            <h2 className='Totalamt'>₹10,900</h2>
                        </div>
                        <h2 className='Save'>You will save ₹9,000 on this order</h2>
                        <div className='continuediv2'>
                            <button className='continue'
                                onClick={() => {
                                    getcart()
                                }}
                            >CONTINUE</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Payment