import React from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import './allcat.css'

function AllCat() {
    return (
        <div>
            <Header />
            <div className='parentdiv'>
                <div className='fullcatdiv'>
                    <h2 className='heading'>All Categories</h2>
                    <div className='catdiv'>
                        <h2 className='catead'>Lenin</h2>
                        <div class="grid-container2">
                            <div class="grid-item2">Lenin Kanchi border</div>
                            <div class="grid-item2">Lenin Bathik prints</div>
                            <div class="grid-item2">Lenin printed</div>
                            <div class="grid-item2">Lenin ikkat</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AllCat