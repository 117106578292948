import React from 'react'
import './footer.css'

function Footer() {
    return (

        <footer
            style={{ position: 'relative', zIndex: '-1' }}
            className="ct-footer" >
            <div className="container">

                <div className='leftdiv'>
                    <h1 className='logoname'>Vedha fashions</h1>
                    <p className='webdesc'>Our Mission is to bring the Worlds best ethnic designs to your doorstep.</p>
                    <p className='webdesc'>Vigneshwara Silks is a traditional wedding wear online store that combines Indian art, traditional fabrics and craftsmanship with contemporary themes to create an assemble that embodies the values of the modern Indian woman especially for weddings. Vigneshwara Silks celebrates the all-aged woman and is committed to unveiled women real beauty with traditional and progressive personality.</p>
                </div>

                <div className='contactdiv'>
                    <h2 className='cinfo'>CONTACT INFO</h2>
                    <div className='allinfo'>
                        <p className='infoh'>Address:</p>
                        <p className='info'>Erragadda : Plot 3, Model Colony, Beside ESI, S.R. Nagar, Hyderabad, Telangana 500038</p>
                        <p className='infoh'>Phone:</p>
                        <p className='info'>+917799878734</p>
                        <p className='infoh'>Email:</p>
                        <p className='info'>contact@vigneshwarasilks.com</p>
                    </div>
                </div>

            </div>

            <div className='bottomfooter'>
                <p className='copyright'>All Copyright @ Vigneshwara Silks</p>
                <div className='socialicons'>
                    <img className='iconimg' src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/768px-Facebook_Logo_%282019%29.png' />
                    <img className='iconimg' src='https://sin.ie/wp-content/uploads/2018/11/697029-twitter-512.png' />
                    <img className='iconimg' src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png' />
                    <img className='iconimg' src='https://i.pinimg.com/originals/6a/42/04/6a4204f04496559aa27101d25983d0f0.png' />
                    <img className='iconimg' src='https://cdn-icons-png.flaticon.com/512/145/145807.png' />
                </div>
            </div>

        </footer>



    )
}

export default Footer