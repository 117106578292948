import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './success.css'
import Aos from 'aos';
import "aos/dist/aos.css";
import ReactWhatsapp from 'react-whatsapp';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { app } from '../Fire';
import Header from '../Header/Header';
import Footer from '../Foorter/Footer';
import { async } from '@firebase/util';

function Success(props) {

    let navigate = useNavigate()

    let params = useParams()
    let db = getFirestore(app)

    const [mData, setmData] = useState()



    useEffect(() => {

        window.addEventListener('popstate', (e) => {

            console.log(e)
            if (window.location.pathname.includes("addressform")) {
                navigate('/')
            }

        });
        Aos.init({ duration: 1000 })

        getOrderDetails()
    }, [])



    function getFabricName(fabric) {
        let name = ""
        props.fabricMap.forEach((val, key) => {
            if (fabric == key) {
                name = val
            }
        })
        return name
    }

    function getColorName(color) {
        let name = ""
        props.colorMap.forEach((val, key) => {
            if (color == key) {
                name = val.name
            }
        })
        return name
    }

  async  function getOrderDetails(){
        try{
            const query = doc(db, "orders", params.payid)

            const snapshot = await getDoc(query)
            console.log(snapshot.data())
            setmData(snapshot.data())
            
        }catch(e){ 
            alert(e)
        }
    }



    function openWhatsapp() {

        let message = "Hey, i have ordered from your website. %0a%0aMy transaction Id is " 
        + params.payid +"%0a%0aMore Details:%0aName:- "+params.name+", %0aMobile:- "+params.mobile +", %0aAddress:- "+localStorage.getItem("addr").replaceAll(',','%0a')+
        "%0a%0aYou can view here at %0a"+window.location
        window.open('https://api.whatsapp.com/send/?phone=8977221061&text=' + message + '&type=phone_number&app_absent=0')
    }
  


    return (
        <div>

    


            <div
            style={{overflow: 'scroll'}}
             className="successbody">


                <section className='Section' style={{marginTop: '40px'}} >
             

                    <div className='mainSuccessDiv'>

                   
                    <div data-aos="zoom-in-up" className="SuccessBoxdiv">


                    <p className='success' style={{fontWeight: 'bolder', fontSize: '20px'}}>Order Successful</p>

                    <div style={{
                     width: '90%', 
                   opacity: '0.8',
                    padding: '20px',
                    margin: '12px'}}>
                    <img className='successimg' src={require('../img/success.png')} />

                   <h1  style={{fontSize: '20px',fontFamily: 'sans-serif',
                }}>₹ {params.amount}</h1>
                    </div>
            
         

                <div className='spacer' >
                <p className='lightTv'>Name</p>
                 <p  className='darkTv'>{params.name}</p>
                </div>
            
                    <p className='line'></p>


                <div className='spacer' >
                <p className='lightTv'>Phone No</p>
                 <p  className='darkTv'>{params.mobile}</p>
                </div>
                <p className='line'></p>

                <div className='spacer' >
                <p className='lightTv'>Order No</p>
                 <p  className='darkTv'>{params.payid}</p>
                </div> 
                <p className='line'></p>
                <div className='spacer' >
                <p className='lightTv'>Delivery Charges</p>
                 <p  className='darkTv'>₹ {mData?.deliveryFee}</p>
                </div> 
                <p className='line'></p>
                <div style={{width: '100%', padding: '12px', textAlign: 'start'}}>
                <p 
                className='styleFont'
                style={{color: 'black', fontWeight: 'bold',
        
            color: '#651564'}}>Address:</p>
                 <p 
                 
                  className='darkTv'
                 style={{ textAlign: 'start',
                 color: '#000',
                 overflow: 'hidden',
                 fontSize: '14px',
                    lineHeight:'20px',
                  whiteSpace: 'pre-line'}}
                 >{''+mData?.address.replaceAll(',', '\n')}</p>
                </div> 

         <div style={{display: 'flex', marginTop: '12px'}}>

         <button className='okaybtn'
         style={{backgroundColor: '#651564',color:'white', margin: '12px'}}

                            onClick={() => {
                                navigate('/qrpage/'+params.payid+'/'+params.mobile+'/'+params.name+'/'+params.address+'/'+params.amount)
                            }}
                        >Payment Details</button>
                       
                    <button className='okaybtn'
                   style={{backgroundColor: '#65156430', color: 'black', margin: '12px'}}
                            onClick={() => {
                                openWhatsapp()
                        
                            }}
                        >Share </button> </div>
           

                        
                    </div>


                            <div 
                      
                            style={{marginTop: '20px'}}
                            className='bagBox'
                           >

                                <h1 
                                className='styleFont'
                                style={{margin: '8px', fontSize: '16px',color: '#651564'}}>Order Details</h1>
                              {
                                mData?.produts.map((val, key)=>{
                                    return(
                                        <div key={key} style={{display: 'flex', alignItems:'center', marginTop: '12px'}}>
                                           <img src={val.img} style={{height: '120px', 

                                           borderRadius: '8px',
                                           objectFit: 'contain'}}/>

                                        <div style={{marginLeft: '8px', width: '100%'}}>
                                        <div className='spacer' style={{width: '100%',
                                         padding: '0px'}} >
                                    <p className='bagtv'>Name:</p>
                                    <p  className='bagValues'>{val.name}</p>
                                    </div>
            
                                    <div className='spacer' style={{width: '100%',
                                    marginTop: '4px',
                                         padding: '0px'}} >
                                    <p className='bagtv'>Price:</p>
                                    <p  className='bagValues'> ₹{val.price}</p>
                                    </div>
                                    <div className='spacer' style={{width: '100%',
                                    marginTop: '4px',
                                         padding: '0px'}} >
                                    <p className='bagtv'>Quantity:</p>
                                    <p  className='bagValues'>{val.quantity}</p>
                                    </div>

                                    <div className='spacer' style={{width: '100%',
                                    marginTop: '4px',
                                         padding: '0px'}} >
                                    <p className='bagtv'>Fabric:</p>
                                    <p className='bagValues'>{getFabricName(val.fabric)}</p>
                                    </div>
                                     
                                    <div className='spacer' style={{width: '100%',
                                    marginTop: '4px',
                                         padding: '0px'}} >
                                    <p className='bagtv'>Color:</p>
                                    <p className='bagValues'>{getColorName(val.color)}</p>
                                    </div>
                                        
                                        </div>
                                          
                                        </div>
                                    )
                                })
                              }
                            </div>
                    </div>
                    <div
                className='boxshadow'
                style={{ display: 'flex',width: '100vw', justifyContent: 'space-between',
                padding: '20px'}}>

                <div className='styleFont'>

                    <h2 className='styleFont' style={{color: '#651564'}}>Payment Screenshot</h2>
                    <h5 style={{ marginTop: '12px', fontSize: '18px' }}>Step: 1</h5>
                    <p>Press the Share on Whatsapp button below to share your order Details to our whatsapp.</p>
                    <h5 style={{ marginTop: '12px' , fontSize: '18px'}}>Step: 2</h5>
                    <p>Do the Payment on the Same Whatsapp Number and Send the Screenshot to confirm Your Order</p>
                </div>



                <img style={{
                    height: '150px'
                }}
                    src={'https://storage.googleapis.com/support-forums-api/attachment/thread-45454772-10846268552009025084.png'} />


            </div>
                </section>



            </div>

                <Footer/>
        </div>
    )
}

export default Success