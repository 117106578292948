import { async } from '@firebase/util'
import { addDoc, collection, getFirestore } from 'firebase/firestore'
import React, { useState } from 'react'
import { app } from '../Fire'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import './contact.css'
import Aos from 'aos';
import "aos/dist/aos.css";

function Contact() {


    let db = getFirestore(app)

    async function getmessage(){
        try{
            await addDoc(collection(db, "message"),{
                email:""+mail,
                message:""+mess
            });
            alert("data added")
        }catch(e){
            alert(e)
        }
    }


    const [mail,setmail] = useState("")
    const [mess,setmess] = useState("")

    return (
        <div>
            <Header />
            <div class="Mainbody3">
                <section  className='mysection3'>
                    <div class="Boxdiv3">
                        <h1 className='LogoName3'>CONTACT US</h1>
                        <p className='contactdesc'>Whether you have a question about features, trials, pricing, need a demo, or anything else, our team is ready to answer all your questions</p>
                        <input
                        value={mail}
                        onChange={(x) => {
                            setmail(x.target.value)
                        }}
                        className='edittext1' type='text' placeholder='Enter Email' />
                        <input
                        value={mess}
                        onChange={(x) => {
                            setmess(x.target.value)
                        }}
                        className='edittext2' type='text' placeholder='Type Message' />
                        <button
                        onClick={() => {
                            getmessage()
                        }}
                        className='signupbtn'>Submit Message</button>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Contact