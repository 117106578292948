import React from 'react'
import './header.css'
import { FaArrowAltCircleLeft, FaArrowDown, FaBabyCarriage, FaFacebook, FaInstagram, FaMailBulk, FaTwitter, FaSearch, FaShoppingCart } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import "aos/dist/aos.css";
import { AiOutlineShoppingCart } from 'react-icons/ai';

function Header() {


    let navigate = useNavigate()

    return (
        <div style={{zIndex : '10', position: 'relative'}}>
            <div className='tophead'>

            <div style={{display: 'flex'}}>
            <img  
            style={{width: '26px', height:'26px', marginLeft: '12px'}}
            src={require('../img/vedhalogo.png')}/>
                <h1
                style={{marginLeft: '4px'}}
            
                 className='Name'>Vedha Fashion <span style={{ fontFamily: 'sans-serif', color: '#000', fontSize: '12px' }}>v.2</span></h1>

            </div>
              
                <div className='Logindiv'>


                    <FaFacebook className='facebook' />
                    <FaInstagram className='facebook' />
                </div>
            </div>
            <div className="nav">

                <input type="checkbox" id="nav-check" />
                <div className="nav-btn">
                    <label
                        onClick={() => {
                            console.log("helo")
                        }}
                        htmlFor="nav-check">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                </div>

                <div className="nav-links">


                    {/* <p className='a'
                        onClick={() => {
                            navigate('/contact')
                        }}
                    >ABOUT US</p> */}


                    <div className='a' >
                     
                        {/* <div className='hover-div'>
                            <p className='hover-a'>Cotton Sarees</p>
                            <p className='hover-a'>Kota Sarees</p>
                            <p className='hover-a'>Super Next Sarees</p>
                            <p className='hover-a'>Silk Sarees</p>
                            <p className='hover-a'>Jute Sarees</p>
                            <p className='hover-a'>Crape Sarees</p>
                            <p className='hover-a'>Gorget Sarees</p>
                            <p className='hover-a'>Shiffon Sarees</p>
                            <p className='hover-a'>Fancy Sarees</p>
                        </div> */}
                    </div>

                    {/* <p
                        onClick={() => {
                            navigate('/orderhistory')
                        }}
                        className='a'>ORDER HISTORY</p> */}
                    {/* <p className='a'
                        onClick={() => {
                            navigate('/category')
                        }}
                    >ALL CATEGORIES</p> */}



                    {/* <p className='a'
                        onClick={() => {
                            navigate('/contact')
                        }}
                    >CONTACT US</p> */}

                    <p
                        onClick={() => {
                            navigate('/')
                        }}
                        className='a'>EMBROIDERY BLOUSES</p>

                            <p
                        onClick={() => {
                            alert("Comming soon")
                        }}
                        className='a'>Saree</p>
  
                    <p
                        onClick={() => {
                            alert("Comming soon")
                        }}
                        className='a'>DRESSES</p>

                    <p
                        onClick={() => {
                            alert("Comming soon")
                        }}
                        className='a'>FABRICS</p>

                </div>

                <div className='myicons'>
                    <a className='headericon2' href='#'
                        onClick={() => {
                            navigate('/cart')
                        }}
                    ><AiOutlineShoppingCart /></a>
                </div>
            </div>
        </div>

    )
}

export default Header