import { addDoc, collection, getFirestore } from 'firebase/firestore'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { app } from '../Fire'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import './refund.css'

function Refund() {

    let db = getFirestore(app)
    let params = useParams()

    async function getrefund(){
        try{
            await addDoc(collection(db, "refunddetails"),{
                Cname:""+name,
                ifsc:""+code,
                Account:""+num,
                message:""+mess,
                id:""+params.docid
            });
            alert("data added")
        }catch(e){
            alert(e)
        }
    }

    const [name,setname] = useState("")
    const [code,setcode] = useState("")
    const [num,setnum] = useState("")
    const [mess,setmess] = useState("")

    return (
        <div>
            <Header/>
            <div class="refund-div">
                <section className='refund-section'>
                    <div class="refund-box">
                    <h1 className='refund-title'>REFUND</h1>
                        <p className='refunddesc'>Please Provide Below given Details to Proceed the Refund As Soon as Possible</p>
                        <input
                        value={name}
                        onChange={(x) => {
                            setname(x.target.value)
                        }}
                         className='refund-edit' type='text' placeholder='Customer Name' />
                        <input
                        value={code}
                        onChange={(x) => {
                            setcode(x.target.value)
                        }}
                         className='refund-edit' type='text' placeholder='IFSC Code' />
                        <input
                        value={num}
                        onChange={(x) => {
                            setnum(x.target.value)
                        }}
                         className='refund-edit' type='text' placeholder='Account Number' />
                        <input 
                        value={mess}
                        onChange={(x) => {
                            setmess(x.target.value)
                        }}
                         className='refund-edit2' type='text' placeholder='Type your reason' />
                        <button
                        onClick={() => {
                            getrefund()
                        }}
                         className='submitbtn'>Submit Message</button>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
}

export default Refund