import { async } from '@firebase/util';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { app } from '../Fire';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import './signup.css'

function SignUp() {

    const [email,setemail] = useState()
    const [password,setpassword] = useState()

    // let db = getFirestore(app)

    //     async  function addmydata(){
    //         try{
    //             const docRef = await addDoc(collection(db, "signup"), {
    //                 name: "Tokyo",
    //                 email: "Japan",
    //                 password:"12345678"
    //             });
    //             alert("data added")
    //         }catch(e){
    //             alert(e)
    //         }

    //         }


    async function mydata(){
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                navigate('/home')
                // ...
            })
            .catch((error) => {
                alert(error)
                // ..
            });
    }

    let navigate = useNavigate()

    return (
        <div>
            <div class="Mainbody">
                <section className='mysection'>
                    <div class="Boxdiv">
                        <h1 className='LogoName'>Vedha Fashions</h1>
                        <h2 className='signup'>Sign Up</h2>
                        <input className='edittext' onChange={(x)=>{
                            setemail(x.target.value)
                        }} name='email' type='text' placeholder='Enter Email' />
                        <input className='edittext' onChange={(x) => {
                            setpassword(x.target.value)
                        }} name='pass' type='text' placeholder='Enter Password' />
                        <button className='signupbtn'
                            onClick={() => {
                                mydata()
                                //   navigate('/home')
                            }}
                        >Sign up</button>


                    </div>
                </section>
            </div>
        </div>
    )
}

export default SignUp