import { getAuth } from 'firebase/auth'
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { app } from '../Fire'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import Progress from '../Progress/Progress'
import './orderhistory.css'
import Aos from 'aos';
import "aos/dist/aos.css";

function OrderHistory() {



    const [arr, setarr] = useState([])
    let mynavigate = useNavigate()
    const [prog, setprog] = useState(false)


    let db = getFirestore(app)
    let temp = []
    let auth = getAuth(app)
    let uid = ""

    useEffect(() => {

        auth.onAuthStateChanged((user) => {
            if (user) {
                getOrderHistory(user.uid)

            }
        })

    }, [])




    async function getOrderHistory(uid) {
        setprog(true)
        try {

            const q = query(collection(db, "orders"), where("uid", "==", uid), orderBy("orderDate", "desc"))
            const snapshot = await getDocs(q)
            snapshot.forEach((x) => {
                temp.push(x)
            })
            setprog(false)
            setarr(temp)
        } catch (e) {
            setprog(false)
            alert(e)
            console.log(e)
        }
    }

    return (
        <div>
            <Header />
            {
                prog ? <Progress /> : null
            }
            <div className='orderparentdiv'>
                <div className='orderchilddiv'>
                    <div className='orderboxdiv'>
                        <div className='orderheaddiv'>
                            <h1 className='orderhead'>Order History</h1>
                        </div>

                        {
                            arr.map((val, index) => {
                                let d = new Date(val.data().deliveryDate.seconds * 1000);
                                return <div className='ordersdiv' key={index}
                                    onClick={() => {

                                        mynavigate('/shipping/' + val.id)
                                    }}
                                >
                                    <img className='orderimg' src={val.data().image} />
                                    <div className='FullOrderdiv'>
                                        <div className='OrderSareediv'>
                                            <p className='orderSareename'>
                                                {"" + val.data().name}
                                            </p>
                                        </div>
                                        <div className='OrderDetail'>
                                            <p className='orderamount'>{"₹ " + val.data().price}</p>
                                            <div className='ordersuccessdiv'>
                                                <div className='dotdiv'>
                                                    <span class="dot"
                                                        style={{
                                                            background: val.data().status == "completed" ?
                                                                'green' : 'red'
                                                        }}
                                                    ></span>

                                                    <p className='deliver'> {"Delivery on " + d.getDate() + "/" + d.getMonth() + "/"
                                                        + d.getFullYear()}</p>
                                                </div>
                                                <p className='deliverdisc'>{val.data().status}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OrderHistory