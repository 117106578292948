import React, { useEffect, useState } from 'react';
import './detail.css'
import { FaFacebook, FaInstagram, FaMailBulk, FaMailchimp, FaMinus, FaPencilAlt, FaPlug, FaPlus, FaTwitter } from "react-icons/fa";
import Header from '../Header/Header';
import Footer from '../Foorter/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, getFirestore, query, setDoc } from 'firebase/firestore';
import { app } from '../Fire';
import { getAuth } from 'firebase/auth';
import Progress from '../Progress/Progress';
import { async } from '@firebase/util';
import Aos from 'aos';
import "aos/dist/aos.css";



function Detail(props) {



    let temp = []
    let params = useParams()
    const [counter, setCounter] = useState(1);
    const [myimg, setmyimg] = useState([])
    const [mainimg, setmainimg] = useState(0)
    const [mData, setmData] = useState()

    let navigate = useNavigate()
    let auth = getAuth(app)
    const [prog, setprog] = useState(false)

    


    let db = getFirestore(app)

    useEffect(() => {
        window.scrollTo(0, 0)
        getimages()
        console.log("helo", props.colorMap)
        console.table(JSON.parse(localStorage.getItem('cart')))

    }, [])


    async function getimages() {
        setprog(true)
        try {

            const q = query(doc(db, "products", params.pid))
            const snapshot = await getDoc(q)
            console.log(snapshot)
            setmData(snapshot.data())
            setmyimg(snapshot.data().images)
            setmainimg(snapshot.data().images[0].img)
           
            Aos.init({ duration: 1000 })
            setprog(false)
        } catch (e) {
            setprog(false)
            alert(e)
        }
    }


    function getLocalArr(model) {
        temp = []
        temp = JSON.parse(localStorage.getItem('cart'))
        console.log("mytable")
        console.table(JSON.parse(localStorage.getItem('cart')))
        if (temp != null) {
            let myindex = alreadyin(temp, model)

            if (myindex == -1) {
                temp.push(model)

                setArraytoDb(temp)
            } else {
                temp[myindex].quantity = model.quantity
                temp[myindex].price = model.price
                setArraytoDb(temp)
            }

        } else {
            setArraytoDb(Array(model))

        }
    }

    function alreadyin(dbarr, model) {

        let index = -1
        for (let i = 0; i < dbarr.length; i++) {
            if (dbarr[i].pid == model.pid) {
                index = i
                break
            }
        }
        return index

    }


    async function setArraytoDb(some) {

        console.log("some")
        console.log(some)
        let t1 = []



        localStorage.setItem("cart", JSON.stringify(some))
        setprog(false)
        alert("added")
        navigate('/cart')


    }

    function getCategory(category) {
        let name = ""
        if(mData != null){

            if (mData.type == "saree") {
                props.sareeMap.forEach((val, key) => {
                    if (category == key) {
                        name = val
                    }
                })
            } else {
                props.blouseMap.forEach((val, key) => {
                    if (category == key) {
                        name = val
                    }
                })
            }

        }
     

        return name
    }


    function getFabricName(fabric) {
        let name = ""
        props.fabricMap.forEach((val, key) => {
            if (fabric == key) {
                name = val
            }
        })
        return name
    }

    function getColorName(color) {
        let name = ""
        props.colorMap.forEach((val, key) => {
            if (color == key) {
                name = val.name
            }
        })
        return name
    }

    function isLoggedIn(model) {
        setprog(true)
        getLocalArr(model)


    }



    return (
        <div  >

            <Header />

            {
                prog ? <Progress /> : null
            }



            <div className='parentdiv' >
                <div className='detailfulldiv' style={{marginTop: '2rem'}}>
                    <div data-aos="fade-up-right" className='imagesdiv'>

                        <div className='column'>
                            {
                                myimg.map((val, index) => {
                                    return <div key={index}>

                                        <img
                                            onClick={() => {
                                                setmainimg(val.img)
                                            }}
                                            className='columnimg' src={val.img} />
                                    </div>
                                })
                            }

                        </div>
                        <div className='fullimg'>
                            <img className='myfullimg' src={mainimg} />
                        </div>
                    </div>
                    <div data-aos="fade-up-left" className='imgdetaildiv'>

                        <h1 className='Sareename'>{params.name}</h1>


                        <p 
                        style={{fontFamily: 'sans-serif'}}
                        className='addquantity'>{"" + getCategory(mData?.category)}</p>


                        <div
                      
                         style={{display: 'flex', flexDirection: 'row'}}>
                            <p 
                                 style={{marginRight: '10px',
                                color: '#595959'}}
                            className='addquantity styleFont'>{"" + getFabricName(mData?.fabric)}</p>
                            <p 
                        
                            className='addquantity styleFont'>   |  </p>
                            <p 
                            
                            style={{marginLeft: '10px',  color: '#595959' }}
                            className='addquantity styleFont'>{""+getColorName(mData?.color)}</p>
                        </div>




                        <h3 className='mrp'>MRP ₹ {params.price}</h3>

                        <p className='tax'>(Incl. of all taxes)</p>


                      
                        <div className='addquantity'>




                            <FaMinus className='addicon'
                                style={{ cursor: 'pointer', color: 'lightgray', }}
                                onClick={() => {
                                    if (counter != 1) {
                                        let count = counter - 1
                                        setCounter(count)
                                    }

                                }} />


                            <p className='quantity' 
                          
                            disabled>{counter} </p>


                       
           
                    <p style={{fontSize: '28px', fontWeight: 'lighter',
                    color: 'lightgray', cursor: 'pointer', userSelect: 'none'}}
                    onClick={()=>{
                     
                        const quantity = mData.quantity
                        if(counter < quantity){
                            let count = counter + 1
                            setCounter(count)
                        }else{
                            alert("You cannot Order more than "+counter)
                        }
                  
                          }}>+</p>
                           
                        </div>

                            {
                                mData?.quantity != 0 ?    <div className='btns'>
                                <button className='addbtn'
                                    onClick={() => {
                                        let model = {
                                            "name": params.name, "price": params.price, "quantity": counter, "pid": params.pid,
                                            "img": myimg[0].img, "status": 'pending', "fabric": mData.fabric, 'color': mData.color, "category": mData.category
                                        }
                                        isLoggedIn(model)
    
                                    }}
                                >Add to Cart</button>
                                <button className='buybtn'
                                    onClick={() => {
                                        let model = {
                                            "name": params.name, "price": params.price, "quantity": counter, "pid": params.pid,
                                            "img": myimg[0].img, "status": 'pending', "fabric": mData.fabric, 'color': mData.color, "category": mData.category
                                        }
                                        isLoggedIn(model)
                                    }}
                                >Buy Now</button>
                            </div> : <h3
                            style={{color: 'red'}}
                            >Not Available</h3>
                            }

                     

                        {mData != null ?
                            <p className='sareedetail'>{mData.description.toUpperCase()}</p>
                            : null}


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Detail