import { arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, serverTimestamp, updateDoc, where, writeBatch } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { app } from '../Fire'
import { FaFacebook } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import './cart.css'
import { async } from '@firebase/util';
import { getAuth } from 'firebase/auth';
import Progress from '../Progress/Progress';
import useRazorpay from 'react-razorpay';
import Aos from 'aos';
import "aos/dist/aos.css";

function Cart() {

    let navigate = useNavigate()
    let params = useParams()

    // Cart Array list
    let temp = []
    const [myarr, setmyarr] = useState([])
    let db = getFirestore(app)
    const [check, setcheck] = useState(0)
    const [total, settotal] = useState(0)
    const [delfee, setdelfee] = useState(50)
    let auth = getAuth(app)


    const [prog, setprog] = useState(false)

    useEffect(() => {


        window.scrollTo(0, 0)
        Aos.init({ duration: 1000 })

        getMydata()

    }, [])






    async function getMydata() {

        try {
            let total_value = 0
            let temp = []
            temp = JSON.parse(localStorage.getItem('cart'))



            if (temp != null) {
                setmyarr(JSON.parse(localStorage.getItem('cart')))
                temp.forEach((x) => {
                    total_value = total_value + parseInt(x.price) * parseInt(x.quantity)
                })
                settotal(total_value)
            }
            setprog(false)


        } catch (e) {
            alert(e)
        }


    }



    async function deletecart(index) {
        setprog(true)
        console.log(temp)

        let old = [...myarr]
        settotal(total - old[index].price * old[index].quantity)
        old.splice(index, 1)
        setmyarr(old)
        alert("deleted")
        setprog(false)
        localStorage.setItem('cart', JSON.stringify(old))
    }





    return (
        <div>
            <Header />


            {
                prog ? <Progress /> : null
            }

            {
                myarr.length == 0 ?

                    <div style={{
                        width: '100vw', height: '100vh',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'center', justifyContent: 'center'
                    }}>


                        <img
                            style={{ height: '200px' }}
                            src={require('../img/cartImg.png')} />

                        <h1 style={{
                            marginTop: '32px'
                        }}>Cart is Empty</h1>

                        <button
                            onClick={() => {
                                navigate("/")
                            }}
                            style={{ marginTop: '40px', width: '220px' }}
                            className='cancelbtn'>Continue shopping</button>

                    </div>
                    :
                    <div className='cartparent'>
                        <div className='childcart'>
                            <div className='cartdiv'>
                                <div data-aos="fade-up" className='headingdiv'>
                                    <h1 className='heading'>Cart</h1>
                                </div>

                             



                                <div data-aos="fade-up">

                                    {
                                        myarr.map((val, key) => {

                                            return <div className='savedcart' key={key}>
                                                <img className='cartimg' src={val.img} />
                                                <div className='details'>
                                                    <div className='deleterow'>
                                                        <h2 className='SareeDetail'>{val.name}</h2>
                                                        <MdDelete
                                                            onClick={() => {

                                                                deletecart(key)

                                                            }}
                                                            className='delete' />
                                                    </div>
                                                    <p className='seller'>Seller : Vedha Silks</p>
                                                    <p style={{ fontFamily: 'sans-serif', color: 'rgb(45, 44, 44)', fontSize: '14px', marginTop: '15px' }}>{"quantity:-  " + val.quantity}</p>
                                                    <p className='amount'>{'₹' + val.price}</p>
                                                </div>
                                            </div>
                                        })
                                    }


                                </div>


                                {
                                    myarr.length != 0 ?

                                        <div className='orderdiv3'>

                                            <button
                                                style={{
                                                    height: '50px'
                                                }}
                                                className='order'
                                                onClick={() => {
                                                    
                                                    navigate('/addressform/'+delfee)
                                                }}>
                                                Place Order
                                            </button>
                                        </div> : null
                                }





                            </div>
                            <div data-aos="fade-up" className='pricediv'>
                                <h2 className='price'>PRICE DETAILS</h2>
                                <div className='myprice'>
                                    <p className='pname'>Price</p>
                                    <p className='amt'>{"₹" + total}</p>
                                </div>
                             
                                <div className='myprice' style={{display: 'flex', flexDirection: 'column'}}>
                                    <p className='pname' style={{fontWeight: 'bold'}}>Delivery Charges</p>

                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                marginTop: '12px'}}>

                                    <div style={{display: 'flex'}}>
                                    <input type='checkbox'
                                    checked={delfee == 50}

                                    onClick={()=>{
                                        setdelfee(50)
                                    }}
                                     style={{accentColor: '#651564'}}/>
                                     <p style={{marginLeft: '8px'}} className='pname'>AP / Telangana:-</p>
                                    </div>
                              
                                        <p className='amt2'>₹50</p>
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                marginTop: '8px'}}>

                            <div style={{display: 'flex'}}>
                                    <input
                                    onClick={()=>{
                                        setdelfee(100)
                                    }}
                                     type='checkbox' 
                                              checked={delfee == 100}
                                    style={{accentColor: '#651564'}}/>
                                     <p style={{marginLeft: '8px'}} className='pname'>Other States:-</p>
                                    </div>
                                   
                                        <p className='amt2'>₹100</p>
                                    </div>
                                 
                                </div>
                                <div className='Tamountdiv'>
                                    <h2 className='styleFont' style={{fontSize: '14px', fontWeight: 'bold'}}>Total Amount </h2>
                                    <h2 className='tamt'>{"₹" + total}</h2>
                                </div>


                                <div style={{display: 'flex', 
                                marginTop: '8px',
                                flexDirection: 'row', justifyContent: 'space-between'}} >
                                  <p className='styleFont' style={{fontSize: '14px'}}>Delivery Charges</p>
                                  <p className='styleFont' style={{fontSize: '16px', fontWeight: 'bold'}}>₹ {delfee}</p>
                                </div>

                            </div>

                        </div>
                    </div>
            }


            <Footer />
        </div>
    )
}

export default Cart