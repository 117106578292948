import { async } from '@firebase/util'
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import React, { Component, useEffect, useState } from 'react'
import { FaAngleDown, FaClosedCaptioning, FaCreativeCommonsNc, FaCross, FaMinus, FaPlus, FaTiktok } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { app } from '../Fire'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import Progress from '../Progress/Progress'
import './grid.css'
import { TiCancel, TiCancelOutline, TiTick, TiTicket } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import ReactSlider from 'react-slider'
import Aos from 'aos';
import "aos/dist/aos.css";
import { AiFillCaretDown } from "react-icons/ai";
import MultiRangeSlider from '../MutlSlider/MultiRangeSlider'


function Grid(props) {
    let navigate = useNavigate()


    let temp = []
    const [myarr, setmyarr] = useState([])
    const [fabarr, setfabarr] = useState([])
    const [colorarr, setcolorarr] = useState([])
    const [catarr, setcatarr] = useState([])


    const [Filter, setFilter] = useState(false)
    const [colorselect, setcolorselect] = useState([])
    const [fabricselect, setfabricselect] = useState()
    const [categoryselect, setcategoryselect] = useState()

    const [colshow, setcolshow] = useState(true)
    const [fabshow, setfabshow] = useState(false)
    const [catshow, setcatshow] = useState(false)
    const [maxpirce, setmaxprice] = useState(8000)
    const [minprice, setminprice] = useState(550)

    let db = getFirestore(app)
    const [prog, setprog] = useState(false)

  

    useEffect(() => {

     
    
        getResults(null, null, null)
        getExtras()
        clearAll()
    }, [window.location.pathname])



    function getExtras() {
        let temp1 = []
        let temp2 = []
        let temp3 = []
        props.fabricMap.forEach((val, key) => {

            let fabModel = { name: val, key: key }
            temp1.push(fabModel)
        });
        setfabarr(temp1)

        if (window.location.pathname == "/") {
            props.blouseMap.forEach((val, key) => {

                let fabModel = { name: val, key: key }
                temp3.push(fabModel)
            });
            setcatarr(temp3)
        } else {
            props.sareeMap.forEach((val, key) => {

                let fabModel = { name: val, key: key }
                temp3.push(fabModel)
            });
            setcatarr(temp3)
        }


        props.colorMap.forEach((val, key) => {

            let fabModel = { name: val, key: key }
            temp2.push(fabModel)
        });
        setcolorarr(temp2)
        console.log("temp1", temp2)



    }








    async function getResults(color, category, fabric) {

        setprog(true)


        console.log(fabric)
        try {
            let q;
            let queryarr = []
            if (color != null) {
                if (color.length != 0) {
                    queryarr.push(where("color", "in", color))
                }
            }



            queryarr.push(where('price', '<=', parseInt(maxpirce)))
            queryarr.push(where('price', '>=', parseInt(minprice)))
            if (window.location.pathname == "/") {

                queryarr.push(where("type", "==", "blouse"))
            } else {
                queryarr.push(where("type", "==", "saree"))
            }

            if (category != null) {
                queryarr.push(where("category", "==", category))
            }

            if (fabric != null) {
                queryarr.push(where("fabric", "==", fabric))
            }


            q = query(collection(db, "products"), ...queryarr, orderBy('price', 'asc'), limit(50))
            let snap = await getDocs(q)
            snap.forEach((val) => {
                temp.push(val)
                Aos.init({ duration: 1000 })
            })

            setmyarr(temp)
            setprog(false)
            console.log(temp)
        } catch (e) {
            setprog(false)
            console.log(e)
        }


    }



    function toggleFun(value) {
        let newValue
        if (value) {
            newValue = false
        } else {
            newValue = true
        }
        return newValue
    }

    function getSortedArray(oldarr, value) {
        let newArr = []
        if (oldarr.includes(value)) {
            oldarr.splice(oldarr.indexOf(value), 1)
            newArr = oldarr
        } else {
            oldarr.push(value)
            newArr = oldarr
        }
        return newArr
    }

    function clearAll() {
        setfabshow(false)
        setcatshow(false)
        setcolorselect([])
        setfabricselect(null)
        setcategoryselect(null)
        setcolshow(true)

    }



    function getFabricList(sheet) {

        return (

            <div style={{
                display: 'flex', overflow: 'scroll',
                flexDirection: 'column',
                position: 'relative', height: '120px'
            }}>
                {
                    fabarr.map((val, key) => {
                        return (



                            <div key={key} style={{
                                display: 'flex', padding: '8px',
                                alignItems: 'center',
                            }}>

                                <div
                                    style={{
                                        background: fabricselect == val.key ? '#CBC3E3' : null, width: '14px', height: '14px',
                                        border: '1px solid gray', borderRadius: '2px'
                                    }}

                                    onClick={() => {

                                        if (fabricselect == val.key) {
                                            setfabricselect(null)

                                        } else {
                                            setfabricselect(val.key)


                                        }


                                    }}
                                ></div>
                                <p
                                className='smalltxt'
                                    style={{

                                        marginLeft: '6px'
                                    }}>{val.name}</p>
                            </div>
                        )

                    })
                }

            </div>

        )


    }


    function getSlider(){
        return(

            <div style={{marginBottom: '12px'}}>


            
            <MultiRangeSlider
                min={550}
                max={8000}
                onChange={({ min, max }) => {
                    setminprice(min)
                    setmaxprice(max)
                }}
            />



            <br />

            <div style={{
                display: 'flex', marginTop: '0px 3px 0px 8px',
                justifyContent: 'space-between'
            }}>
                  <p >₹ {minprice}</p>
                <p >₹ {maxpirce}</p>
            </div>

        </div>

        )
    }

    function getcategoryList(sheet) {

        return (

            <div style={{
                display: 'flex', overflow: 'scroll',
                flexDirection: 'column',

                position: 'relative', height: '100px'
            }}>
                {
                    catarr.map((val, key) => {
                        return (
                            <div key={key} style={{
                                position: 'relative',
                                padding: '8px',
                                height: '100px',
                                
                                display: 'flex',
                                alignItems: 'center'
                            }}>


                                <div
                                    onClick={() => {
                                        if (categoryselect == val.key) {
                                            setcategoryselect(null)

                                        } else {
                                            setcategoryselect(val.key)
                                        }


                                    }}
                                    style={{
                                        background: categoryselect == val.key ? '#CBC3E3' : null, width: '14px', height: '14px',
                                        border: '1px solid gray', borderRadius: '2px'
                                    }}>

                                </div>

                                <p
                                className='smalltxt'
                                    style={{
                                        marginLeft: '6px'
                                    }}>{val.name}</p>
                            </div>
                        )
                    })
                }


            </div>
        )

    }

    function getcolorList(sheet) {
        return (

            <div style={{ display: 'grid', gridTemplateColumns: '40px 40px 40px 40px 40px', overflow: 'scroll', position: 'relative' }}>
                {
                    colorarr.map((val, key) => {
                        return (
                            <div
                                style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems:'center' }}
                                onClick={() => {

                                    let temp = getSortedArray([...colorselect], val.key)
                                    setcolorselect(temp)


                                }}
                                key={key} >


                                {/* <TiTick style={{
                                    position: 'absolute',
                                    color: 'green',
                                    fontSize: '18px',
                                    display: colorselect.includes(val.key) ? null : 'none',
                                    zIndex: '9'
                                }} /> */}


                                <div
                                    style={{
                                        width: colorselect.includes(val.key) ? '38px' : '32px',
                                         height: colorselect.includes(val.key) ? '38px' : '32px', background: '' + val.name.code,
                                        borderRadius: '10%',
                                        marginTop: '10px', 
                                        padding: '2px', marginRight: '4px'
                                    }}>


                                </div>



                            </div>

                        )

                    })
                }

            </div>

        )
    }


    function getRotation(val){
        if(val){
            return 'rotate(45deg)'
        }else{
           return null
        }
    }



    return (
        <div>

            {/* this is header */}





            <Header />

            {
                prog ? <Progress /> : null
            }




            <div className='Topdiv' style={{ display: 'flex', justifyContent: 'space-between' }}>

                <p className='Totalsaree'>We have found<span style={{ marginLeft: '5px', fontWeight: 'bold', color: '#000' }}>{myarr.length} Best Buy</span></p>


            </div>


            {/* // my new sheet */}
            {
                Filter ? <div className='btncontainer sheetdiv'>
                    <div className='mysheet'>




                        <div style={{
                            padding: '14px', background: '#d9d9d9', width: '100vw',
                            borderTopRightRadius: '20px',
                            display: 'flex', justifyContent: 'space-between',
                        }}>

                            <h5 style={{

                            }}>Sort By</h5>
                            <MdCancel
                                onClick={() => {
                                    setFilter(false)
                                }}
                                style={{ color: 'black' }} />
                        </div>




                            <div>

                            <h3 
                            style={{backgroundColor: 'purple', display: 'flex', color: 'white',
                            padding: '8px'}}>Price:-</h3>
                                <div style={{display: 'flex', marginTop: '8px', position: 'relative',
                            alignItems: 'center', justifyContent: 'center'}}>

                               

                               {
                                    getSlider()
                                }

                                </div>

                            </div>
                     

                             

                        <div
                            onClick={() => {
                                setcolshow(!colshow)
                            }}
                            style={{
                                display: 'flex', justifyContent: 'space-between', backgroundColor: 'purple',
                                padding: '12px', alignItems: 'centers'
                            }}>
                            <p style={{ color: 'white' }}>Colors</p>

                            <FaAngleDown
                                style={{
                                    transform: colshow ? null : 'rotate(-180deg)'
                                }}

                                color='white' />

                        </div>
                      
                       

                              
                               


                        <div style={{ padding: '0px 12px 0px 12px' }}>
                            {colshow ?
                                getcolorList(true) : null

                            }
                        </div>

                        <div
                            onClick={() => {
                                setfabshow(!fabshow)
                            }}
                            style={{
                                display: 'flex', justifyContent: 'space-between', marginTop: '2px', backgroundColor: 'purple',
                                padding: '12px', alignItems: 'centers'
                            }}>

                            <p style={{ color: 'white' }}>Fabrics</p>
                            <FaAngleDown
                                style={{
                                    transform: fabshow ? null : 'rotate(-180deg)'
                                }}

                                color='white' />
                        </div>

                        <div style={{ padding: '0px 12px 0px 12px' }}>

                            {fabshow ?
                                getFabricList(true) : null

                            }
                        </div>


                        <div
                            onClick={() => {
                                setcatshow(!catshow)
                            }}
                            style={{
                                display: 'flex', justifyContent: 'space-between', marginTop: '2px', backgroundColor: 'purple',
                                padding: '12px', alignItems: 'centers'
                            }}>

                            <p style={{ color: 'white' }}>Category</p>
                            <FaAngleDown
                                style={{
                                    transform: catshow ? null : 'rotate(-180deg)'
                                }}

                                color='white' />
                        </div>

                        <div style={{ padding: '12px' }}>
                            {catshow ?
                                getcategoryList(true) : null

                            }

                        </div>


                    </div>



                    <div className='applyfilter' style={{ backgroundColor: 'white', padding: '12px' }}>
                        <button
                            onClick={() => {
                                setFilter(false)
                                clearAll()
                                getResults(null, null, null)
                            }}
                            className='cancelbtn'>Clear All</button>


                        <button onClick={() => {
                            setFilter(false)
                            getResults(colorselect, categoryselect, fabricselect)
                        }}
                            className='applybtn'>Apply</button>
                    </div>
                </div> : <div className='btncontainer'>
                    <div className='sortbox'>
                        <button className='by'
                            onClick={() => {
                                setFilter(true)
                                setcolshow(true)
                                setfabshow(true)
                                setcatshow(true)
                            }}
                            style={{ border: 'none', background: 'purple' }}>Filter</button>
                    </div>

                </div>
            }






            <div className='bodydiv'>

                {/* This is gray box */}
                <div className='mybox'>
                    <div className='filterdiv'>
                        <p className='filter'>Filter By</p>
                        <p className='clear'
                            onClick={() => {
                                clearAll()
                                getResults(null, null, null)
                            }}
                        >CLEAR ALL</p>
                    </div>
                    <div className='allfilters'>


                        <div>




                        {
              getSlider()
                            }




                            <div

                                className='SortDiv'>



                                <p className='titles'  >Color</p>

                                <FaPlus
                                 
                                    onClick={() => {
                                        setcolshow(toggleFun(colshow))
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'gray',
                                        transform: getRotation(colshow)
                                    }} />
                            </div>

             
              

                            {
                                colshow ?
                                    <div style={{ display: 'flex', overflow: 'scroll', position: 'relative' }}>

                                        {
                                            getcolorList()
                                        }

                                    </div>

                                    : null
                            }
                        </div>


                        <div className='SortDiv'>

                        <p className='titles'>Fabric</p>
                            <FaPlus
                                onClick={() => {
                                    setfabshow(toggleFun(fabshow))
                                }}
                                style={{   cursor: 'pointer', color: 'gray', transform: getRotation(fabshow) }} />
                         

                        </div>
                        {
                            fabshow ?
                                getFabricList()
                                : null
                        }
                        <div className='SortDiv'>
                            {/* <input type="checkbox" id="sort-check" /> */}
                            <p className='titles'>Category</p>
                            <FaPlus
                                onClick={() => {
                                    setcatshow(toggleFun(catshow))
                                }}
                                style={{ 
                                    cursor: 'pointer',
                                    color: 'gray',  transform: getRotation(catshow) }} />
                        
                        </div>
                        {
                            catshow ?
                                getcategoryList() : null
                        }
                    </div>

                    <button
                        className='applyBtn'
                        onClick={() => {
                            getResults(colorselect, categoryselect, fabricselect)
                        }}
                    >Apply Filters</button>

                </div>


                {
                    myarr.length == 0 ? <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        width: '100vw', flexDirection: 'column'
                    }}>
                        <img
                            style={{
                                height: '300px', width: '400px', objectFit: 'cover'
                            }}
                            src={require('../img/noitems.png')} />

                        <h1 style={{
                            marginTop: '12px'
                        }}>No Items Found</h1>
                    </div> : <div

                        className='mygrid'>

                        {
                            myarr.map((val, key) => {
                                return <div className='grid'
                                    data-aos="fade-up"
                                    key={key}
                                    onClick={() => {
                                        navigate('/detail/' + val.data().name + '/' + val.data().price + '/' + val.id)

                                    }}

                                    style={{
                                        objectFit: 'cover',
                                        margin: '2px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '1rem',

                                        cursor: 'pointer'
                                    }}>
                                    <img
                                        className='sareeimg'
                                        src={val.data().images[0].img} />

                                    <p className='mytitle'>{String(val.data().name).substring(0, 25)}</p>
                                    <h4 style={{ marginTop: '10px', fontFamily: 'sans-serif', cursor: 'pointer', fontSize: '17px', color: 'rgb(45, 44, 44)' }}>{'₹' + val.data().price}</h4>
                                </div>
                            })
                        }
                    </div>
                }

            </div>





            <Footer />




        </div>

    )
}

export default Grid