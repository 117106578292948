import React from 'react'
import './progress.css'

function Progress() {
    return (
        <div className='body'>
            <div className="dashboard">
                <svg className='svg'>
                    <circle className="bg" cx="57" cy="57" r="52" />
                    <circle className="meter-1" cx="57" cy="57" r="52" />
                </svg>
          
            </div>
        </div>
    )
}

export default Progress