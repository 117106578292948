import { doc, getDoc, getFirestore } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { app } from '../Fire'
import Footer from '../Foorter/Footer'
import Header from '../Header/Header'
import Progress from '../Progress/Progress'
import './shipping.css'

function Shipping() {

    let params = useParams()
    let navigate = useNavigate()

    const [name, setname] = useState("")
    const [price, setprice] = useState("")
    const [address, setaddress] = useState("")
    const [img, setimg] = useState("")
    const [mobile, setmobile] = useState("")
    const [status, setstaus] = useState("")
    let db = getFirestore(app)
    const [prog, setprog] = useState(false)

    useEffect(() => {
     getOrderDetails()
    }, [])
    
    async function getOrderDetails(){
        setprog(true)
        try{
            const docRef = doc(db, "orders", ""+params.docid);
            const docSnap = await getDoc(docRef);
            setname(docSnap.data().name)
            setprice(docSnap.data().price)
            setaddress(docSnap.data().address)
            setmobile(docSnap.data().mobile)
            setimg(docSnap.data().image)
            setstaus(docSnap.data().status)
            setprog(false)

        }catch(e){
            setprog(false)
            alert(e)
        }
     


    }

    return (
        <div>
            <Header />
            {
                prog ? <Progress/> : null
            }
            <div className='shippingparent'>
                <div className='shippingchild'>
                    <div className='shippingaddress'>
                        <h2 className='Deladdress'>Delivery Address</h2>
    
                        <p className='address'>{address}</p>
                        <div className='phndetails'>
                            <p className='phonenbr'>Phone number</p>
                            <p className='number'>{mobile}</p>
                        </div>
                    </div>


                    <div className='Ordersdiv' >
                        <div className='orderedsaree'>
                            <img className='cartimg' src={img} />
                            <div className='details'>
                                <h2 className='Sareedetail'>{name}</h2>
                                <p className='SareeColor'>White, Pure Silk</p>
                                <p className='Seller'>Seller : Vedha Silks</p>
                                <p className='Amount'>{"₹ "+price}</p>
                            </div>
                        </div>
                        <div className='processdiv'>
                            <div className='Prodiv'>
                                <div className='dotorder'>
                                    <span className='orderdot'
                                    style={{background: 'red'}}
                                    ></span>
                                    <p className='orderSaree'>{status}</p>
                                </div>
                            </div>
                            <div className='refunddiv'>
                                <button
                                onClick={() => {
                                    navigate('/refund/'+params.docid)
                                }}
                                className='refund'>Refund</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Shipping