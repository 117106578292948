import { Route, Routes } from 'react-router-dom';
import './App.css';
import Cart from './Cart/Cart';
import Detail from './DetailPage/Detail';
import Grid from './Grid/Grid';
import OrderHistory from './Order History/OrderHistory';
import Shipping from './Shipping/Shipping';
import SignUp from './Sign Up/SignUp';
import AddressForm from './Address Form/AddressForm';
import AllCat from './All Category/AllCat';
import Payment from './Payment Page/Payment';
import Contact from './Contact Us/Contact';
import Success from './Success Page/Success';
import Testing from './Test/Testing';
import { useEffect, useState } from 'react';
import Refund from './Refund/Refund';
import ColorFilter from './Color Filter/ColorFilter';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { app } from './Fire';
import { getAuth } from 'firebase/auth';
import Progress from './Progress/Progress';
import QRPage from './QRPage/QRPage';



function App() {


  let auth = getAuth(app)
  const [fabMap, setfabMap] = useState()
  const [sareeMap, setsareeMap] = useState()
  const [blouseMap, setblouseMap] = useState()

  const [colorMap, setcolorMap] = useState()
  const [loaded, setloaded] = useState(false)
  useEffect(() => {

    getAllExtras()
    window.scrollTo(0, 0)
  }, [])

  let db = getFirestore(app)




  async function getAllExtras() {
    try {
      let db = getFirestore(app)
      const fabref = doc(db, "extras", "fabrics")
      const sareeRef = doc(db, "extras", "sareetypes")
      const blouseRef = doc(db, "extras", "blousetypes")
      const colorRef = doc(db, "extras", "mycolors")

      const blousesnap = await getDoc(blouseRef)
      const sareesnap = await getDoc(sareeRef)
      const snapshot = await getDoc(fabref)
      const colorsnap = await getDoc(colorRef)
      setfabMap(new Map(Object.entries(snapshot.data().mymap)))
      setsareeMap(new Map(Object.entries(sareesnap.data().mymap)))
      setblouseMap(new Map(Object.entries(blousesnap.data().mymap)))
      setcolorMap(new Map(Object.entries(colorsnap.data().mymap)))

      setloaded(true)
    } catch (e) {
      setloaded(true)
      alert(e)
    }
  }



  return (


    <div>
      {
        loaded ? <Routes>
          <Route index element={<Grid fabricMap={fabMap}
            sareeMap={sareeMap} blouseMap={blouseMap} colorMap={colorMap} />} />
          <Route path='orderhistory' element={<OrderHistory />} />
          <Route path='shipping/:docid' element={<Shipping />} />
          <Route path='detail/:name/:price/:pid' element={<Detail 
           fabricMap={fabMap}
           sareeMap={sareeMap} blouseMap={blouseMap} colorMap={colorMap}
          />}

           
          />
          <Route path='cart' element={<Cart />} />
          <Route path='signup' element={<SignUp />} />
          <Route path='addressform/:delivery' element={<AddressForm />} />

          <Route path='category' element={<AllCat />} />
          <Route path='payment/:allAddress/:num' element={<Payment />} />
          <Route path='contact' element={<Contact />} />
          <Route path='success/:payid/:mobile/:name/:address/:amount' element={<Success 
             fabricMap={fabMap}
             sareeMap={sareeMap} blouseMap={blouseMap} colorMap={colorMap}/>} />
          <Route path='passname' element={<Detail />} />
          <Route path='refund/:docid' element={<Refund />} />
          <Route path='qrpage/:payid/:mobile/:name/:address/:amount' element={<QRPage/>}/>
          <Route path='blouses' element={<Grid fabricMap={fabMap}
            sareeMap={sareeMap} blouseMap={blouseMap} colorMap={colorMap} />} />
        </Routes> : <Progress />
      }
    </div>







  );
}

export default App;
