import React from 'react'
import { useParams } from 'react-router-dom'
import Header from '../Header/Header'
import './QRPage.css'

function QRPage() {


    const params = useParams()
    return (




        <div className='qrContainer'>



            <div className='subContainer boxshadow'>
                <img className='qrImage  boxshadow' src={require('../img/QrImage.png')} />


       

                    <p className='qrAmount'>₹{params.amount}</p>


                {/* <div className='spacer'>

                    <p className='qrTitle'>PhonePe</p>
                    <p>8977221061@axl</p>
                </div>


                <div className='spacer'>

                    <p className='qrTitle'>GPay</p>
                    <p>8977221061@axl</p>
                </div>


                <div className='spacer'>

                    <p className='qrTitle'>Paytm</p>
                    <p>8977221061@axl</p>
                </div> */}

                <button className='qrSharebtn'>
                    Share
                </button>

            </div>


            <div className='stepMain'>

                <h1 className='paymentTV'>Payment Steps</h1>
                <div class="stepcontainer">
                    <ul className="progressbar">
                        <li className="active">Scan QR Code</li>
                        <li>Enter The Given Amount</li>
                        <li>Share the payment Screenshot</li>
                    </ul>
                </div>
            </div>




        </div>


    )
}

export default QRPage