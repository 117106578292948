import { getAuth } from 'firebase/auth'
import { addDoc, collection, doc, FieldValue, getFirestore, increment, serverTimestamp, setDoc, writeBatch } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { app } from '../Fire'
import Progress from '../Progress/Progress'
import './addressform.css'

function SignUp() {


    const [num, setnum] = useState("")
    const [name, setname] = useState("")

    let params = useParams()
    const [prog, setprog] = useState(false)

    const [hno, sethno] = useState("")
    const [appartment, setappartment] = useState("")
    const [flat, setflat] = useState("")
    const [street, setstreet] = useState("")
    const [colony, setcolony] = useState("")
    const [village, setvillage] = useState("")
    const [currentState, setcurrentState] = useState("Telangana")
    const [district, setdistrict] = useState("")
    const [pin, setpin] = useState("")


    const [address, setaddress] = useState("")
    let db = getFirestore(app)
    let auth = getAuth(app)

    const [isaddress, setisaddress] = useState(false)


    let stateList = [
        "Telangana",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",

        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"]

    async function makeOrder(addr) {

        const batch = writeBatch(db);
        setprog(true)
        try {



            let payment_id = "pay-" + makeDateId();
            const orderRef = doc(db, "orders", payment_id);
            batch.set(orderRef, {
                produts: JSON.parse(localStorage.getItem('cart')),
                totalAmount: getTotalAmount(),
                transId: payment_id,
                mobile: '' + num,
                time: serverTimestamp(),
                name: '' + name,
                address: '' + addr,
                deliveryFee: ''+params.delivery,
                mode: 'Cash On Delivery',
                status: 'pending'
            })


            let temp = JSON.parse(localStorage.getItem('cart'))
            temp.forEach((val) => {

                const orderRef = doc(db, "products", val.pid);
                batch.update(orderRef, {
                    quantity: increment(-val.quantity)
                })
            })

            await batch.commit()
            setprog(false)
            navigate('/success/' + payment_id + '/' + num + '/' + name + '/' + "s" + "/" + getTotalAmount())

            localStorage.removeItem('cart')



        } catch (e) {
            setprog(false)
            alert("" + e)


        }
    }





    function getTotalAmount() {
        let total = 0

        if (JSON.parse(localStorage.getItem('cart') != null)) {
            let temp = JSON.parse(localStorage.getItem('cart'))

            temp.forEach((val) => {
                total = total + parseInt(val.price) * parseInt(val.quantity)
            })
        }

        return total
    }




    function makeDateId() {
        let mdate = new Date()
        let mynewDate = mdate.getFullYear() + "" + mdate.getMonth() + "" + mdate.getDate() + "" + mdate.getHours() + "" + mdate.getMinutes() + "" + mdate.getSeconds()
        return mynewDate

    }





    useEffect(() => {


        if (localStorage.getItem('cart') != null) {
            let myarr = []
            myarr = JSON.parse(localStorage.getItem('cart'))

            let size = myarr.length

            if (size == 0) {
                window.history.back()
                alert("You have nothing in your cart")
            }
        }

    }, [])


    function validate() {


        if (num == "" || name == "") {
            alert("Please Enter All Feilds")
        } else if (!num.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && !(num.match(/0{5,}/))) {
            alert("Invlaid Mobile Number")
        } else {
            if (isaddress) {
                if (hno == "" || appartment == "" || flat == "" || street == "" ||
                    colony == "" || village == "" || pin == "") {
                    alert("Please Enter All Feilds")
                } else {
                    let maddress = "House No:- " + hno + ",Appartment:- " + appartment 
                    + ",Flat:- " + flat + ",Street:- " + street + ",Colony:- " + colony +
                    ",State:- " + currentState +",District:- "+district +
                     ",Village:- " + village + "," + pin
                    localStorage.setItem("addr", maddress)
                    makeOrder(maddress + "")
                }

            } else {
                setisaddress(true)

            }

        }


    }

    let navigate = useNavigate()

    return (
        <div>
            {/* 
            <div style={{display: 'flex', 
            position: 'absolute',
            justifyContent: 'center',
            top: '90%',
            width:'100%', backgroundColor: 'white', padding: '30px'}}>

                <p  style={{textAlign: 'center'}}>Tracking No will be sent to Phone after next day of ordered date</p>

            </div> */}

            {
                prog ? <Progress /> : null
            }
            <div className="Mainbody2">
                <section className='mysection2'

                >
                    <div className="Boxdiv2"
                    >
                        <h1 className='LogoName2'>Vedha Fashions</h1>
                        <h2 className='Address'>Enter Details</h2>


                        {
                            isaddress == false ? <input className='edittext' type='text'
                                placeholder='Enter Name'
                                value={name}
                                onChange={(x) => {
                                    setname(x.target.value)
                                }} /> : null
                        }


                        {
                            isaddress ?

                                <div>

                                    <input className='edittext fullEt' type='text'
                                        value={hno}
                                        onChange={(x) => {
                                            sethno(x.target.value)
                                        }}
                                        placeholder='House Number' />
                                    <div className='centerHalf'>

                                        <input className='edittext halfEt' type='text'
                                            value={appartment}
                                            onChange={(x) => {
                                                setappartment(x.target.value)
                                            }}
                                            placeholder='Appartment Name' />


                                        <input className='edittext halfEt' type='text'
                                            value={flat}
                                            onChange={(x) => {
                                                setflat(x.target.value)
                                            }}
                                            placeholder='Flat Number' />

                                    </div>
                                    <input className='edittext fullEt' type='text'
                                        value={street}
                                        onChange={(x) => {
                                            setstreet(x.target.value)
                                        }}
                                        placeholder='Street Number' />

                                    <input className='edittext fullEt' type='text'
                                        value={colony}
                                        onChange={(x) => {
                                            setcolony(x.target.value)
                                        }}
                                        placeholder='Colony Name' />


                                    <div className='centerHalf'>

                                        <input className='edittext halfEt' type='text'
                                            value={village}
                                            onChange={(x) => {
                                                setvillage(x.target.value)
                                            }}
                                            placeholder='Village' />


                                        <input className='edittext halfEt' type='text'
                                            value={district}
                                            onChange={(x) => {
                                                setdistrict(x.target.value)
                                            }}
                                            placeholder='District' />

                                    </div>


                                    <div className='centerHalf' style={{

                                        alignItems: 'center', justifyContent: 'center'
                                    }}>
                                        <select
                                            onChange={(x) => {
                                                setcurrentState(x.target.value)
                                            }}
                                            className='halfEt' style={{
                                                padding: '10px',
                                                marginTop: '18px',
                                                background: 'rgba(255, 255, 255, 0.519)',
                                                marginRight: '12px',
                                                outline: 'none', borderRadius: '8px',
                                            }}>
                                            {
                                                stateList.map((val, key) => {
                                                    return (
                                                        <option

                                                            key={key}
                                                            value={val}
                                                        >{val}</option>
                                                    )

                                                })
                                            }


                                        </select>

                                        <input className='edittext halfEt' type='text'
                                            value={pin}
                                            onChange={(x) => {
                                                setpin(x.target.value)
                                            }}
                                            placeholder='Pin Code' />


                                    </div>


                                </div>

                                :
                                <input className='edittext' type='tel'
                                    maxLength={10}
                                    value={num}

                                    onChange={(x) => {
                                        setnum(x.target.value)
                                    }}
                                    placeholder='Phone Number' />
                        }

                        <button className='signupbtn'
                            onClick={() => {
                                validate()

                            }}
                        >Submit and Place Holder</button>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default SignUp